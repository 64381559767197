// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'core-js';
import smoothscroll from 'smoothscroll-polyfill';
import 'regenerator-runtime/runtime';
import 'javascripts/controllers';
import 'flatpickr/dist/flatpickr.css';
import 'stylesheets/application.scss';
import 'stylesheets/admin.scss';
import 'whatwg-fetch';
import { default as apply_element_closest_polyfill } from 'element-closest';
import Rails from '@rails/ujs';
import handleSweetAlertConfirm from '../lib/sweet_alerts';

if (!window._rails_loaded) {
  Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleSweetAlertConfirm);
  Rails.delegate(document, 'button[data-confirm-swal]:not([form])', 'click', handleSweetAlertConfirm);
  Rails.start();
}
require('trix');
require('@rails/actiontext');
require('@rails/activestorage').start();
require('../lib/action_text_overrides');
require('../lib/polyfills/element-remove');
require('turbolinks').start();

apply_element_closest_polyfill(window); // Run polyfill setup
smoothscroll.polyfill(); // Run polyfill setup

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
